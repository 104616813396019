html,
body {
	margin: 0;
	padding: 0;
}

html[schema='dark'] input:not(.on_login):-webkit-autofill,
html[schema='dark'] input:not(.on_login):-webkit-autofill:hover,
html[schema='dark'] input:not(.on_login):-webkit-autofill:focus,
html[schema='dark'] input:not(.on_login):-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #2f3141 inset !important;
	-webkit-text-fill-color: white !important;
}

html[schema='light'] input:-webkit-autofill,
html[schema='light'] input:-webkit-autofill:hover,
html[schema='light'] input:-webkit-autofill:focus,
html[schema='light'] input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* 
    Seletores CSS para as linhas do head e footer das tabelas 
    ficarem com letras maisculas, uppercase, caixa alta
*/
th > span.MuiTableSortLabel-root > span,
th > span,
tbody tr:last-of-type:not(:first-child) > td,
th.MuiTableCell-head {
	text-transform: uppercase;
}
/* mainPainelDataView > tbody tr:last-of-type:not(:first-child) > td */
/* Remove o uppercase de elementos filhos que nao precisam estar em maisculo */
th.MuiTableCell-head > div,
div[name='mainPainelDataView'] tbody tr:last-of-type:not(:first-child) > td {
	text-transform: none;
}

/* 
    Seletores CSS para as linhas do head da tabela estatica do React Admin 
    que precisam ficar alinhadas com os valores nas linhas do body e footer
*/
span[data-field='valor_pendente'],
span[data-field='valor_total'],
span[data-field='valor'],
span[data-field='multa'],
span[data-field='valor_base'],
span[data-field='juros'],
span[data-field='correcao_monetaria'],
span[data-field='desconto'],
span[data-field='valor_recebido'],
span[data-field='valor_da_leitura'],
span[data-field='consumo_atual'],
span[data-field='ultima_leitura'],
span[data-field='penultimo_consumo'],
span[data-field='penultima_leitura'],
span[data-field='saldo_inicial'],
span[data-field='coeficiente_garagem'],
span[data-field='desconto'], 
span[data-field='leitura'],
span[data-field='consumo'] {
	display: block !important;
	text-align: right !important;
}
