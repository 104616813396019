.rst__rowTitle {
	font-weight: unset;
}

.rst__virtualScrollOverride {
	padding: 20px;
}

.tree-icon-table {
	transition: transform 200ms ease 0s !important;
	transform: rotate(0deg);
}

.tree-icon-table.expanded {
	transform: rotate(90deg);
}
